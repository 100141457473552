import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import { Flow } from "../form/flow";

export const ConfirmFormSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SHeading>
          <SectionHeading jp="お問い合わせ" en="Contact" position="center" />
        </SHeading>
        <Flow flow01="false" flow02="true" flow03="false" />
        <SForm>
          <div>
            <span>
              ■会社名
              <p>デマンドリンク株式会社</p>
            </span>
          </div>
          <div>
            <span>
              ■お名前
              <p>デマンド太郎</p>
            </span>
          </div>
          <div>
            <span>
              ■メールアドレス
              <p>sample@xxxx.com</p>
            </span>
          </div>
          <div>
            <span>
              ■関心のある商品
              <p>ゴルフ関連製品</p>
              <p>付属ソフト関連製品</p>
            </span>
          </div>
          <div>
            <span>
              ■お問い合わせ内容
              <p>
                XXX製品の無料デモのご相談です。
                <br />
                サンプルテキストサンプルテキストサンプルテキストサンプルテキスト
              </p>
            </span>
          </div>
          <SBtn>
            <div>
              <Link to="/contact/thanks/">上記の内容で送信する</Link>
            </div>
            <div>
              <Link to="/contact/">内容を修正する</Link>
            </div>
          </SBtn>
        </SForm>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
  }
`;

const SHeading = styled.div`
  text-align: center;
`;

const SForm = styled.div`
  width: 800px;
  margin: auto;
  margin-top: 2rem;
  div {
    margin-top: 3rem;
    span {
      display: flex;
      flex-direction: column;
      font-size: 0.9rem;
      font-weight: 500;
      color: ${Color.dark};
      line-height: 1;
      p {
        opacity: 0.8;
        margin-top: 1.5rem;
        width: 100%;
        border: none;
        font-size: 0.9rem;
        line-height: 1;
        transition: border 0.2s;
      }
    }
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const SBtn = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    text-align: center;
    &:first-of-type {
      margin-top: 0;
      a {
        background-color: ${Color.primary};
        color: ${Color.light};
      }
    }
    &:last-of-type {
      margin-top: 1rem;
      a {
        background-color: ${Color.gray};
        color: ${Color.dark};
      }
    }
    a {
      padding: 1rem 2rem;
      display: inline-flex;
      justify-content: center;
      border-radius: 8px;
      text-decoration: none;
      font-weight: 500;
      font-size: 0.9rem;
      min-width: 320px;
    }
  }
  /* TB */
  @media screen and (max-width: 1023px) {
    div {
      a {
        width: 100%;
      }
    }
  }
`;
