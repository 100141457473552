import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const Flow = (props) => {
  return (
    <SWrapper>
      <SInner data-flow-state={props.flow01}>入力</SInner>
      <SBar></SBar>
      <SInner data-flow-state={props.flow02}>確認</SInner>
      <SBar></SBar>
      <SInner data-flow-state={props.flow03}>完了</SInner>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SInner = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Color.primary};
  border-radius: 100vh;
  width: 64px;
  height: 64px;
  line-height: 1;
  font-size: 0.9rem;
  font-weight: 500;
  &[data-flow-state="true"] {
    background-color: ${Color.primary};
    color: #fff;
  }
  &[data-flow-state="false"] {
    background-color: #fff;
    color: ${Color.primary};
  }
`;

const SBar = styled.div`
  content: "";
  width: 1.5rem;
  height: 2px;
  background-color: ${Color.primary};
`;
