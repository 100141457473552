import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

export const SectionHeading = (props) => {
  return (
    <SSectionHeading
      data-heading-position={props.position}
      className="section-heading"
    >
      <span>{props.en}</span>
      <span>{props.jp}</span>
    </SSectionHeading>
  );
};

const SSectionHeading = styled.div`
  display: inline-flex;
  flex-direction: column;
  span {
    color: ${Color.dark};
    line-height: 1;
    &:first-of-type {
      font-size: 2rem;
      font-weight: 700;
    }
    &:last-of-type {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  &[data-heading-position="left"] {
    span {
      text-align: left;
    }
  }
  &[data-heading-position="center"] {
    span {
      text-align: center;
    }
  }
  &[data-heading-position="right"] {
    span {
      text-align: right;
    }
  }
`;
