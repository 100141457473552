import React from "react";
import { Link } from "gatsby";

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { Layout } from "../../components/layout/layout";
import { ConfirmFormSection } from "../../components/section/confirmFormSection";

const ConfirmPage = () => {
  return (
    <Layout
      title="フォームの確認 | LIME"
      jpUrl="/contact/confirm/"
      enUrl="/en/contact/"
    >
      <Breadcrumbs>
        <Link to="/" tabIndex="-1">
          ホーム
        </Link>
        <span>/</span>
        <Link to="/contact/" tabIndex="-1">
          お問い合わせ
        </Link>
        <span>/</span>
        <Link to="/contact/confirm/" tabIndex="-1">
          フォームの確認
        </Link>
        <span>/</span>
      </Breadcrumbs>
      <ConfirmFormSection />
    </Layout>
  );
};

export default ConfirmPage;
